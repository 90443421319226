import React, { Component } from "react";
import './../../components/textures/textures.scss';
import $ from 'jquery';


class Textures extends Component{
  
  showModal = () => {
    $('.overlay-full').fadeIn();
  }

  render() {
    return (
        <div className="actions actions-textures">
            <h3>Slabs</h3>
            <p>Select your slab</p>
            <button onClick={this.showModal} className="btn btn-primary btn-add-slab">
              <svg xmlns="http://www.w3.org/2000/svg" width="13.195" height="13.195" viewBox="0 0 13.195 13.195">
                <path id="Path_106" data-name="Path 106" d="M16.76,13.382H14.4V11.019a.507.507,0,1,0-1.015,0v2.363H11.019a.486.486,0,0,0-.507.507.491.491,0,0,0,.507.507h2.363V16.76a.492.492,0,0,0,.507.507.5.5,0,0,0,.507-.507V14.4H16.76a.507.507,0,1,0,0-1.015Z" transform="translate(-7.292 -7.292)" fill="#fafafa"/>
                <path id="Path_107" data-name="Path 107" d="M9.972,4.263A5.707,5.707,0,1,1,5.935,5.935,5.672,5.672,0,0,1,9.972,4.263m0-.888a6.6,6.6,0,1,0,6.6,6.6,6.6,6.6,0,0,0-6.6-6.6Z" transform="translate(-3.375 -3.375)" fill="#fafafa"/>
              </svg>
                Add slab
            </button>
        </div>
    );
  }
}
export default Textures;
