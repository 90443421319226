import React from "react";
import './../../components/world/world.scss';
import * as THREE from "three";
import Layouts from './../../utils/layouts';
import Texture from './../../utils/textures';
import TextureLoad from '../textures/load'; 
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls';

interface Props{
  changeTexture: any;
  loadAllTextures: any;
}


class World extends React.Component<Props>{
  
  public mount:any;
  public scene:THREE.Scene | any;
  public renderer:any;
  public camera:any;
  public layouts = Layouts;
  public textures = Texture;
  public frameId:any;
  public raycaster = new THREE.Raycaster();
  public mouse = new THREE.Vector2();
  public objectDrag:any;
  public DRAG = false;
  public freedomMesh:any;
  public textureSelected:any;
  public controls:OrbitControls | any;

  public generateTexture = false;

  public isScrolling:any;

  public modelsObj:THREE.Mesh[];
  public modelsText:THREE.Mesh[];

  constructor(props:Props){
    super(props);
    this.modelsObj = [];
    this.modelsText = [];
  }

  componentDidMount() { 
   
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.scene = new THREE.Scene(); 

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor("#F3F3EB");
    this.renderer.name = "World";
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    //add Camera
    this.camera = new THREE.PerspectiveCamera(18, width / height, 2, 1000);
    this.camera.position.z = 10
    this.camera.position.y = 0.5;

    // Add generate Models and Textures for app
    //this.generateTextures();
    //this.generateModels();

    this.renderScene();

    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.disabledControls();

    this.start();

    document.addEventListener( 'mousedown', this.onPointerMove );
    document.addEventListener( 'mouseup', this.onPointerUp );
    document.addEventListener( 'mousemove', this.moveObject );
    document.addEventListener("wheel", this.zoomScroll);

  }

  zoomScroll = () => {

    this.enableControls();
    window.clearTimeout( this.isScrolling );
    
    let obj = this;

    this.isScrolling = setTimeout(function() {
      
      obj.disabledControls();

    }, 126);

  }

  enableControls = () => {
    this.controls.enableRotate = false;
    this.controls.enabled = true;
    this.controls.enablePan = true;
    this.controls.screenSpacePanning = true;
  }

  disabledControls = () => {
    this.controls.enabled = false;
  }

  loadTexture(image:string, plane:any){

    if(!this.textureSelected){
      this.textureSelected =  new THREE.TextureLoader().load(
        image,
        texture => {
          //Update Texture
          plane.material.map = texture;
          plane.material.needsUpdate = true;
        },
        error => {
        }
      );
    }else{
      plane.material.map = this.textureSelected;
      plane.material.needsUpdate = true;
    }


  }

  createTextures(x:number = 0, y:number = 1.5, objTexture:any){

    const geometry = new THREE.PlaneGeometry( 1.8, 1.4,1,1 );
    const material = new THREE.MeshBasicMaterial( {transparent: false, depthWrite: true, depthTest:false, alphaTest: 0} );
    const plane = new THREE.Mesh( geometry, material );

    const {image, name, type} = objTexture;
    
    const loadText = new TextureLoad();
    const promise = loadText.init(image, plane);
    promise.then((texture:any) =>{
      this.textureSelected = texture;
    }).catch((e) => console.log(e) );

    plane.name = name;
    plane.position.setX(x);
    plane.position.setY(y);
    plane.type = type;
    plane.renderOrder = 0;
    
    this.scene.add( plane );

    if(this.modelsText){
      this.modelsText.push(plane);
    }


  }

  removeAllModels = () => {
    if(this.modelsObj && this.modelsObj.length > 0){
      const obj = this;
      this.modelsObj.map(model => obj.scene.remove(model));
      this.modelsObj = [];
    }
  }

  removeAllTextures = () => {
    if(this.modelsText && this.modelsText.length > 0){ 
      const obj = this;
      this.modelsText.map(model => obj.scene.remove(model));
      this.modelsText = [];
    }
  }

  createModels = (x:number = 0, y:number = 1.5, layout:any, w:number = 4, h:number = 3.5) => {

    const { image, name, type, preview } = layout;
    const geometry = new THREE.PlaneGeometry( w, h, 1, 1 );
    const material = new THREE.MeshBasicMaterial( {transparent: true, depthWrite: false, depthTest:true, alphaTest: 0} );
    const plane = new THREE.Mesh( geometry, material );
    
    new THREE.TextureLoader().load(
      image,
      texture => {
        plane.material.map = texture;
        plane.material.needsUpdate = true;
      },
      error => {
      }
    );

    plane.position.setX(x);
    plane.position.setY(y);

    Object.defineProperty(plane, 'preview', {
      value: preview
    });
    
    plane.name = name;
    plane.type = type;
    plane.renderOrder = 10;

    this.scene.add( plane );

    if(this.modelsObj){
      this.modelsObj.push(plane);
    }

  }

  insertElement = (array:THREE.Intersection[]) => {

    array.forEach((item) => {;
      if(item.object.type === "layout"){
        this.objectDrag = item.object;
        this.DRAG = true;
      } 
    });

  };

  captureTexture = () => {

    const intersects = this.raycaster.intersectObjects(this.scene.children);
    if (intersects.length > 0) {
        intersects.find( item => item.object.type === "texture" );
    }

  }

  intersectsTexture = () => {

    this.raycaster.setFromCamera( this.mouse, this.camera );
    let intersects = this.raycaster.intersectObjects( this.scene.children );

    let existTexture = intersects.find( (item) => item.object.type === "texture" );

    if(existTexture){
      
      let item = intersects.find( (item) => item.object.type === "layout" );

      if(item){
        this.props.changeTexture(this.objectDrag.preview, this.textures[0].image , item.point.x, item.point.y);
        this.objectDrag.position.setX(item.point.x);
        this.objectDrag.position.setY(item.point.y);
        this.objectDrag.material.update = true;
      }

    }
    
  }

  moveObject = (event:any) => {  
    
    const position = this.getPositionMove(event);

    this.mouse.x = position.x;
    this.mouse.y = position.y;

    if(this.DRAG){

      this.intersectsTexture();
      
    }
    
  }

  getPositionMove = (event: any) =>{

    const r = this.renderer.domElement.getBoundingClientRect();
    const x = event.clientX - r.left;
    const y = event.clientY - r.top;

    const a = 2*x/this.renderer.domElement.width - 1;
    const b = 1 - 2*y/this.renderer.domElement.height;

    return { x: a, y: b };

  }

  onPointerMove = ( event:any ) => {

    if(this.mount){

      const position = this.getPositionMove(event);

      this.mouse.x = position.x;
      this.mouse.y = position.y;

      this.raycaster.setFromCamera( this.mouse, this.camera );
      let intersects = this.raycaster.intersectObjects( this.scene.children );
  
      if (intersects.length === 0) {
        return false; 
      }

      this.insertElement(intersects);

    }

  }

  onPointerUp = ( event:any ) => {
    this.DRAG = false;
    this.objectDrag = new THREE.Object3D();
  }
    
  generateTextures = () => {
    this.generateTexture = true;
    this.removeAllTextures();
    this.createTextures(0, 0.72, this.textures[0]);
    this.createTextures(0, -0.75, this.textures[0]);
    this.props.loadAllTextures(this.layouts, this.textures[0].image);
  }

  generateModels = () => {
    this.removeAllModels();
    this.createModels(-0.40, 0.71, this.layouts[0], 0.25, 1.2);
    this.createModels(-0.034, 1.160, this.layouts[1], 0.45, 0.3 );
    this.createModels(0.47, 1.160, this.layouts[2], 0.52, 0.3 );
    return { canTexture: this.generateTexture, layouts: this.layouts, texture: this.textures[0].image };
  }

  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId);
  }

  animate = () => {
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  }

  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  }

  render() {
    return (
      <div className="col-12 col-md-5 world">
        <div
            style={{ width: "100%", height: "600px" }}
            ref={mount => {
            this.mount = mount;
            }}
        />
      </div>
    );
  }
}
export default World;
