import React, { Component } from "react";
import Layouts from "./../../components/layouts/layouts";
import './../../components/sidebar/sidebar.scss';
import Textures from './../../components/textures/textures'

interface Props{
  chooseLayout: any;
}

class Sidebar extends Component<Props>{
 
  render() {
    return (
      <div className="col-md-2 sidebar">
        <div className="options-s sidebar-n">
            <Layouts chooseLayout={this.props.chooseLayout}  />
        </div>
        <div className="options-s sidebar-s">          
            <Textures />          
        </div>
      </div>
    );
  }
}
export default Sidebar;
