import React  from "react";
import './../../components/layouts/layouts.scss';
import Item from "./../../components/layouts/item";

interface Props{
    chooseLayout: any;
}

class Modal extends React.Component<Props>{
  
  render() {
    return (
        <div className="modal fade" id="modalLayout" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Layout
                            <small>Select your kitchen</small></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body layouts-modal">
                        
                        <div className="row">
                            <Item chooseLayout={this.props.chooseLayout} title="Broken Peninsula Kitchen" active={true} image="1.png" />
                            <Item chooseLayout={this.props.chooseLayout} title="G-Shape Peninsula Kitchen" active={false} image="2.png" />
                            <Item chooseLayout={this.props.chooseLayout} title="L-Shape Rectangle Kitchen" active={false} image="3.png" />
                            <Item chooseLayout={this.props.chooseLayout} title="L-Shape Kitchen" active={false} image="4.png" />
                            <Item chooseLayout={this.props.chooseLayout} title="Single Row Galley Kitchens" active={false} image="5.png" />
                            <Item chooseLayout={this.props.chooseLayout} title="U-Shape Island Kitchen" active={false} image="6.png" />
                        </div>

                    </div>
                </div>
           </div>
        </div>
    );
  }
}
export default Modal;
