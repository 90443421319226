import * as THREE from "three";

class TextureLoad {


  public init = async (image: string, plane: any, load: boolean = true) => {

    return new Promise((resolve, reject) => {

      new THREE.TextureLoader().load(image,
        texture => {
          if (load) {
            
            plane.material.map = texture;
            plane.material.needsUpdate = true;
          }
          resolve(texture);
        },
        error => {
          reject(error);
        }
      )

    });

  }
}

export default TextureLoad;