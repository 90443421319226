import React from "react";
import './../../components/textures/textures.scss';

interface Props{
    title: string
    active: boolean
    image: string
    chooseTexture: any
}


class Item extends React.Component<Props>{ 

  buttonDisable = () => {
        if(!this.props.active){
            return <button disabled className="btn btn-primary">Select</button>;
        }else{
            return <button onClick={this.props.chooseTexture} className="btn btn-primary">Select</button>;
        }
  }  

  render() {

    return (
        <div className="col-12 cada-texture">
            <div className="row">
                <div className="col-3">
                    <img alt="Logo" src={process.env.PUBLIC_URL + '/assets/images/textures/'+this.props.image}   />  
                </div>
                <div className="col-9">
                    <h3>{ this.props.title }</h3> 
                    { this.buttonDisable() }    
                </div>  
            </div>
        </div>

    );
  }

}
export default Item;
