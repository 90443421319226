const layouts = [
	{
		name: 'meson',
		type: 'layout',
		preview: 'Cube051_Marble_0',
		image: "./assets/layouts/type-1/1@2x.png"
	},
	{
		name: 'meson2',
		type: 'layout',
		preview: 'Cube039_Marble_0',
		image: "./assets/layouts/type-1/2@2x.png"
	},
	{
		name: 'meson3',
		type: 'layout',
		preview: 'Cube041_Marble_0',
		image: "./assets/layouts/type-1/3@2x.png"
	}
];

export default layouts;