import React from 'react';
import Header from './components/header/header';
import Preview from './components/preview/preview';
import Sidebar from './components/sidebar/sidebar';
import Breadcrumb from './components/breadcrumb/breadcrumb';
import ModalTexture from './components/textures/modal';
import ModalLayout from './components/layouts/modal';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; // Archivo CSS de Bootstrap 4 
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'; 
import $ from 'jquery';

import './App.scss';
import World from './components/world/world';


class App extends React.Component{

  public childWorld:any;
  public childSidebar:any;
  public childPreview:any;

  constructor(props:any){
    super(props);
    this.childWorld = React.createRef();
    this.childPreview = React.createRef();
    this.changeTexture = this.changeTexture.bind(this);
    this.chooseLayout = this.chooseLayout.bind(this);
  }

  chooseLayout = () => {
    const obj = this.childWorld.current.generateModels();
    this.childPreview.current.init(obj);
 }

  chooseTexture = () => {
    $('.overlay-full').fadeOut(400, () => {
      this.childWorld.current.generateTextures();   
    }); 
  }

  loadAllTextures = (obj:any, texture:string) => {
    this.childPreview.current.loadAllTextures(obj, texture);
  }

  changeTexture = (obj:any, texture:string, x:number, y:number) => {
    this.childPreview.current.changeTextureChild(obj, texture, x, y);
  }

  render(){
    return (
      <div className="App">
        <Header />
        <Breadcrumb />
        <div className="container">
          <div className="row content-de">
            <Sidebar ref={this.childSidebar}  chooseLayout={this.chooseLayout} />
            <World ref={this.childWorld} loadAllTextures={this.loadAllTextures} changeTexture={this.changeTexture} />
            <Preview ref={this.childPreview} />
          </div>
        </div>
        <ModalTexture chooseTexture={this.chooseTexture} />
        <ModalLayout chooseLayout={this.chooseLayout}  />
      </div>
    ); 
  }

}

export default App;
