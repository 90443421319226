import React  from "react";
import './../../components/textures/textures.scss';
import Item from "./../../components/textures/item";
import $ from 'jquery';

interface Props{
    chooseTexture: any;
}

class Modal extends React.Component<Props>{
  
   closeModal = () => {
        $('.overlay-full').fadeOut();
   }

  render() {
    return (
        <div className="overlay-full">
            <div className="nav-textures">
                <div className="col-12 text-right">
                     <button onClick={this.closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="col-12">
                    <h2>Slab</h2>
                    <p>Select your material</p>
                    <hr></hr>
                    <Item title="Granite" chooseTexture={this.props.chooseTexture} active={true} image="1.png" />
                    <Item title="Marble" chooseTexture={this.props.chooseTexture} active={false} image="2.png" />
                    <Item title="Limestone" chooseTexture={this.props.chooseTexture} active={false} image="3.png" />
                    <Item title="Slate" chooseTexture={this.props.chooseTexture} active={false} image="4.png" />
                </div>
            </div>
        </div>
    );
  }
}
export default Modal;
