import React from 'react';
import './../../components/header/header.scss';

class Header extends React.Component {
 
    render() {
   
        return (

            <nav className="navbar navbar-expand-md navbar-dark bg-white">
                <div className="container">
                    <span className="navbar-brand">
                        <img alt="Logo" src={process.env.PUBLIC_URL + '/assets/images/logo2.png'}   />
                    </span>
                </div>
            </nav>
           
        );
      
    }
   
  }
   
  export default Header;