import React from 'react';
import './../../components/breadcrumb/breadcrumb.scss';
import $ from 'jquery';

class Breadcrumb extends React.Component {
    
    public menu = false;

    toggleMenu = () => {

        if(this.menu) {
            this.menu = false;
        }else{
            this.menu = true;
        }
        $('.sidebar').fadeToggle(300);

    }

    render() {
   
        return (

            <div className="breadcrumb-e">
                <div className="container">
                    <button className="btn btn-secundary back" onClick={this.toggleMenu}>
                            Menu
                    </button>
                </div>   
            </div>
           
        );
      
    }
   
  }
   
  export default Breadcrumb;