import React from "react";
import './../../components/layouts/layouts.scss';

interface Props{
    title: string
    active: boolean
    image: string
    chooseLayout: any
}


class Item extends React.Component<Props>{ 

  buttonDisable = () => {
        if(!this.props.active){
            return <button disabled className="btn btn-primary">Select</button>;
        }else{
            return <button onClick={this.props.chooseLayout} data-bs-dismiss="modal" className="btn btn-primary">Select</button>;
        }
  }  

  render() {

    

    return (
        <div className="col-12 col-md-4 cada-layout">
            <div className="row">                  
                <div className="col-12">
                    <h3>{ this.props.title }</h3> 
                </div>
                <div className="col-5">
                    <img alt="Logo" src={process.env.PUBLIC_URL + '/assets/images/layouts/'+this.props.image}   />  
                </div>
                <div className="col-7">
                    <div><b>Length:</b> 12’4” | 3.76 m</div>
                    <div><b>Width:</b> 10’6” | 3.2 m</div>
                    <div><b>Island Counter Depth:</b> 3’4” </div>
                    <div><b>Bar Top Depth:</b> 1’4” | 41 cm</div>
                    <div><b>Clearance:</b> 3’ | .91 m </div>
                    <div><b>Area:</b> 109 ft2 | 10.1 m2</div>
                </div>      
                <div className="col-12">
                    { this.buttonDisable() }                    
                </div>
            </div>
        </div>

    );
  }

}
export default Item;
