import React from "react";
import './../../components/layouts/layouts.scss';
import { ReactComponent as  Svg } from './icon.svg';

interface Props{
  chooseLayout: any;
}

class Layouts extends React.Component<Props>{
  
  public state = {
    layout: false
  }

  render() {
    return (
        <div className="actions actions-textures">
            <h3>Layout</h3>
            <p>Select your scheme</p>
            <button className="btn btn-secundary btn-add-slab"  data-bs-toggle="modal" data-bs-target="#modalLayout">
              Layout_1_KT
              <Svg />
            </button>
        </div>

    );
  }

}
export default Layouts;
