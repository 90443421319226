import React from "react";
import * as THREE from "three";
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls';
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader'

import TextureLoad from '../textures/load'; 

interface Texture {
    name: string;
    texture: THREE.Texture
}

class Preview extends React.Component {

  public mount: any;  
  public scene: THREE.Scene | any;
  public renderer: any;
  public camera: any;
  public cube: any;
  public frameId: any;
  public freedomMesh: any;

  public raycaster = new THREE.Raycaster();
  public mouse = new THREE.Vector2();
  public color = new THREE.Color();
  public textures:Texture[] = [];
  public textureSelected = new THREE.Texture();
  public moveTex = false;


  public selectedObject = null;

  moveTexture = (child:THREE.Mesh<any, any>, texture:THREE.Texture,  x:number = 0, y:number = 0) => {
      texture.wrapS = THREE.MirroredRepeatWrapping;
      texture.wrapT = THREE.MirroredRepeatWrapping;
      texture.offset.x = x;
      texture.offset.y = y;
      child.material = new THREE.MeshBasicMaterial({map: texture});
      child.material.map = texture;
      child.castShadow = false;
      child.material.castShadow = false;
      child.material.needsUpdate = true;
  }


  changeTexture = (child:THREE.Mesh<any, any>, texture:string, x:number, y:number) => {

    let cursorX = x;
    let cursorY = y;
    let exist = this.textures.find( item =>  item.name === child.name );

    if(!this.moveTex && !exist){  
      this.moveTex = true;
      let loadText = new TextureLoad();
      let promise = loadText.init(texture, child, false);
      promise.then((texture:any) =>{
        this.moveTex = false;
        this.addChildTexture(child, texture);
        this.moveTexture(child, texture,  cursorX, cursorY);
      }).catch((e) => console.log(e) );
    }

    if(exist){ 
      this.moveTexture(child, exist.texture, cursorX, cursorY);
    }  
    
  }

  addChildTexture = (child:THREE.Mesh<any, any>, texture:THREE.Texture) => {
    
    let exist = this.textures.find( item =>  item.name === child.name );

    if(!exist){
      this.textures.push({name:child.name, texture});
    }

  }


  loadAllTextures = (obj:[], texture:string) => {

    if(obj && obj.length > 0){

      obj.forEach((item:any) => {

        if(this.freedomMesh){
          
          this.freedomMesh.traverse((child: any) => {

            child.castShadow = false;
            child.receiveShadow = false;

            if (child instanceof THREE.Mesh) {  
                if(child.name === item.preview){
                  let promise = new TextureLoad().init(texture, child, false);
                  promise.then((texture:any) =>{
                    this.moveTex = false;
                    
                    this.addChildTexture(child, texture);
                    this.moveTexture(child, texture,  0, 0);
                  }).catch((e) => console.log(e) );
                }
            }
          });
        }

      });
    }

  }

  changeTextureChild = (preview:string, texture:string, x:number = 0, y:number = 0) => {
    
    let cursorX = x;
    let cursorY = y;

      this.freedomMesh.traverse((child: any) => {

        if (child instanceof THREE.Mesh) {  
            if(child.name === preview){
              this.changeTexture(child, texture, cursorX, cursorY);
            }
              
        }

      });
      
      return this.state;
      
  }

  addDirectionalLights = () =>{
    const color = 0xFFFFFF;
    const intensity = 0.1;
    const light = new THREE.DirectionalLight(color, intensity);
    light.target.position.set(-1, 1, -1);
    this.scene.add(light);
    this.scene.add(light.target);
  }


  moreLights = () =>{

    let lights = [];
    lights[0] = new THREE.PointLight(0xffffff, 1, 0);
    lights[1] = new THREE.PointLight(0xffffff, 1, 0);
    lights[2] = new THREE.PointLight(0xffffff, 1, 0);
    lights[0].position.set(0, 100, 0);
    lights[1].position.set(100, 100, 100);
    lights[2].position.set(-100, -100, -100);
    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);

  }

  addLights = () => {

    const color = 0xFFFFFF;
    const intensity = 1;
    const light = new THREE.AmbientLight(color, intensity);
    this.scene.add(light);

    this.addDirectionalLights();


  }

  getChildObject = (obj:any) => {

    let gltfLoader = new GLTFLoader();
    const url = './assets/models/kitchen3/scene.gltf';

    const ref = this;

    gltfLoader.load(
      url,
      (object) => {

        const scale = 0.014;

        this.freedomMesh = object.scene;
        this.freedomMesh.position.setY(-1 );
        this.freedomMesh.position.setX(0);
        this.freedomMesh.scale.set(scale, scale, scale);
        this.scene.add(this.freedomMesh);

        if(obj && obj.canTexture){
          ref.loadAllTextures(obj.layouts, obj.texture);
        }

      },
      // called when loading has errors
      error => { console.log(error) });

  }


  componentDidMount() {


    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.scene = new THREE.Scene();

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });
    this.renderer.setClearColor("#F3F3EB");
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    //add Camera
    this.camera = new THREE.PerspectiveCamera(20, width / height, 0.1, 1000);
    this.camera.position.z = 22;
    this.camera.position.y = 5;

    //Camera Controls
    new OrbitControls(this.camera, this.renderer.domElement);

    this.addLights();

    //this.init();

    this.start();

  }

  init(obj: any) {

    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.getChildObject(obj);

  };



  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId);
  }

  animate = () => {

    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
    
  }
  
  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  }

  render() {
    return ( <div className = "col-12 col-md-5 preview" >
      <div style = {
        {
          width: "100%",
          height: "600px"
        }
      }
      ref = {
        mount => {
          this.mount = mount;
        }
      }
      /> </div>
    );
  }
}

export default Preview;